@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  margin: 0;
  padding: 0;
  font-family: "open Sans",sans-serif 
}

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

/* @layer utilities {
  .imp {
    margin: 10px !important;
    transform: translate(1120px, 58px) !important;
  }
  .imp_sm {
    margin: 10px !important;
    transform: translate(-60px, 58px) !important;
  }
} */
