@import url('https://cdn.syncfusion.com/ej2/material.css');
*{
    margin: 0;
    padding: 0;
    font-family: "open Sans",sans-serif 
  }
  
/*added css*/
.slick-next::before,.slick-prev::before {
    opacity: 1 !important;
    font-size: 25px !important;
    color: black;
}

.slick-next {
    right: 7px;
    visibility: hidden;
   
}

.image-container:hover > .slick-next {
    visibility: visible !important;
  
}

.slick-list {
    margin: 0 -2px;
   
}

.slick-prev::before {
    opacity: 1 !important;
    font-size: 25px !important;
    
}

.slick-prev {
    left: 7px;
    visibility: hidden;
    z-index: 2;
    font-size: 30px !important;
}

.image-container:hover > .slick-prev {
    visibility: visible !important;
    z-index: 222222 !important;
   
}

.LazyLoad {
    opacity: 0;
    transition: all 3s ease-in-out;
    
  }
  
  .is-visible {
    opacity: 1;
  }

/* end of added css*/

.sidebar {
    box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}

.nav-item,
.navbar {
    z-index: 10000;
}
*{
    margin: 0;
    padding: 0;
    font-family: "open Sans",sans-serif 
  }
@media screen and (max-width:800px) {
    .sidebar {
        z-index: 10000000;
    }
}

.e-dlg-center-center,
.e-quick-popup-wrapper.e-device {
    z-index: 1000000 !important;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
     background-color: rgb(216, 216, 216); 
    /* background-color: black; */
    border-radius: 40px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

/* color-picker style  */

#preview {
    background: transparent url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png') no-repeat;
    display: inline-block;
    height: 80px;
    margin: 10px 0;
    min-width: 300px;
    max-width: 600px;
    background-color: #008000;
}

.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {
    border: none;
}